import React, { useState, useEffect } from "react";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import PaginationButton from "./PaginationButton";
import DisabledButton from "./DisabledButton";
import { useIntl, Link } from "gatsby-plugin-react-intl";

const Pager: React.FC = (props) => {
    const intl = useIntl();
    const {
        previousPagePath,
        nextPagePath,
        numberOfPages,
        humanPageNumber,
    } = props.pageContext;

    const pageList = [{ id: 1, slug: "/blog" }];
    function generateSlugs() {
        for (var i = 0; i < numberOfPages; i++) {
            if (i != 0) pageList[i] = { id: i + 1, slug: "/blog/" + (i + 1) };
        }
    }
    generateSlugs();
    return (
        <div className="pager-container">
            {previousPagePath != "" ? (
                <div className="pager-button-next-prev flex-center">
                    <AnchorLink
                        className="pager-button"
                        to={
                            intl.formatMessage({
                                id: "lang",
                            }) + previousPagePath
                        }
                    >
                        PREVIOUS
                    </AnchorLink>
                </div>
            ) : (
                <div></div>
            )}
            {pageList.map((s) =>
                s.id == humanPageNumber ? (
                    <div className="pager-button-container flex-center">
                        <AnchorLink className="pager-button" key={s.id} to="">
                            {s.id}
                        </AnchorLink>
                    </div>
                ) : (
                    <div className="pager-button-container flex-center">
                        <AnchorLink
                            className="pager-button"
                            key={s.id}
                            to={
                                intl.formatMessage({
                                    id: "lang",
                                }) + s.slug
                            }
                        >
                            {s.id}
                        </AnchorLink>
                    </div>
                )
            )}
            {nextPagePath != "" ? (
                <div className="pager-button-next-prev flex-center">
                    <AnchorLink
                        to={
                            intl.formatMessage({
                                id: "lang",
                            }) + nextPagePath
                        }
                    >
                        NEXT
                    </AnchorLink>
                </div>
            ) : (
                <div></div>
            )}
        </div>
    );
};
export default Pager;
